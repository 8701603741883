:root {
  --header: #C3F73A;
  --secondary-background: #F7F7F9;
  --primary-background: #F7F7F9;
  --primary-font: #2B2D42;
  --secondary-font: #C62E65;
  --border: #7D8491;
  --doc-height: 100%;
}

.App {
  text-align: center;
  background: var(--primary-background);
  color: var(--primary-font);
}

.header {
  color: var(--primary-font);
}

.link-container {
  display: flex;
  width: 200px;
  justify-content: space-around;
  align-items: center;
  margin: 10px;
}

.section-container {
  scroll-snap-type: y mandatory;
  height: 100vh;
  height: var(--doc-height);
  overflow-y: scroll;
}

.section {
  position: relative;
  scroll-snap-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  align-items: center;
  font-size: calc(10px + 2vmin);
  height: var(--doc-height);
}

.link {
  padding: calc(10px + 2vmin);
  font-size: calc(20px + 2vmin);
}

a:link,
a:visited,
a:hover,
a:active {
  text-decoration: none;
}