.header-container {
  display: flex;
  width: 100vw;
  justify-content: flex-end;
  height: 50px;
  align-items: center;
  background-color: var(--header);
  position: sticky;
  z-index: 10;
  top: 0;
}

.header-link,
.header-link:visited,
.header-link:hover {
  color: var(--primary-font);
}