.name {
  color: var(--secondary-font);
  white-space: nowrap;
}

.about-me {
  display: inline-block;
}

.about-me-text {
  height: 66vh;
  justify-content: space-evenly;
  display: flex;
  flex-direction: column;
  width: 90%;
}