.work-wrapper {
  background-color: var(--secondary-background);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  height: 100vh;
  text-align: left;
  width: 100vw;
}

.work-content {
  height: 80%;
  width: 90%;
}

.work-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

img {
  width: 90%;
  height: auto;
  border: 5px solid var(--border);
  border-radius: 10px;
}

.list {
  text-align: left;
}

.title {
  font-weight: bold;
  font-size: 1.5em;
  color: var(--primary-font);
}

.work-sub-section {
  margin: 25px 0;
}


.title:visited {
  color: var(--primary-font);
}

.photo {
  display: flex;
  justify-content: center;
}

.fa-ul>li {
  margin: 10px 0;
}

@media (min-width: 900px) {
  .title {
    width: 100%;
  }

  .work-content {
    width: 80%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: flex-start;
  }

  .work-sub-section {
    flex: 1;
    margin: 15px;
    width: 25%;
    height: auto;
  }

  .work-details {
    flex-direction: row;
  }

  .photo img {
    width: 100%;
    height: auto;
    margin: 5px 5px 10px 5px;
  }

  .list {
    height: 80%;
  }

  .work-details {
    margin: 0 5px 10px 0;
  }
}